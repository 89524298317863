
export default {
  props: {
    to: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['disabledLinkClickHandler'],
  computed: {
    getHref() {
      return this.$t(this.to) || this.to || ''
    },

    isInternal() {
      return this.getHref && this.getHref.startsWith('/') && !this.getHref.includes('.html')
    },
  },
}
