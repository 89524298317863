
import Link from '@/components/ui/Link/Link.vue'
import PDEButton from '@/components/pde/PDEButton.vue'

export default {
  name: 'Message',

  components: {
    'app-link': Link,
    PDEButton,
  },

  props: {
    title: {
      type: String,
      default: 'identity.message.success',
      required: true,
    },

    messages: {
      type: Array,
      required: true,
    },

    ctaLabel: {
      type: String,
      default: '',
      required: false,
    },

    ctaLocation: {
      type: String,
      default: '',
      required: false,
    },

    anchorLabel: {
      type: String,
      default: '',
      required: false,
    },

    anchorLink: {
      type: String,
      default: '',
      required: false,
    },

    action: {
      type: Function,
      default: () => {},
    },

    showForgotPasswordLink: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  computed: {
    getMessages() {
      return this.messages.map(msg => this.$t(msg) || msg)
    },

    getCTALabel() {
      return this.$t(this.ctaLabel) || this.ctaLabel
    },

    getAnchorLabel() {
      return this.$t(this.anchorLabel) || this.anchorLabel
    },
  },

  methods: {
    submit() {
      this.action()
      this.$router.push({ path: this.ctaLocation })
    },
  },
}
